import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Motion_Detection/Alarm_Notification/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_Notification/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Notification",
  "path": "/Motion_Detection/Alarm_Notification/VGA_Series/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setting up and activating the email alarm.",
  "image": "./MD_SearchThumb_AlarmNotification_VGA.png",
  "social": "/images/Search/MD_SearchThumb_AlarmNotification_VGA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Alarm-Notification_white.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Notification' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setting up and activating the email alarm.' image='/images/Search/MD_SearchThumb_AlarmNotification_VGA.png' twitter='/images/Search/MD_SearchThumb_AlarmNotification_VGA.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_Benachrichtigung/VGA_Serie/' locationFR='/fr/Motion_Detection/Alarm_Notification/VGA_Series/' crumbLabel="Notifications" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alarm-notification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarm-notification",
        "aria-label": "alarm notification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Notification`}</h1>
    <h2 {...{
      "id": "vga-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vga-camera-series",
        "aria-label": "vga camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Camera Series`}</h2>
    <p>{`Setting up and activating the email alarm.`}</p>
    <h3 {...{
      "id": "step-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-01",
        "aria-label": "step 01 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 01`}</h3>
    <p>{`In order that the camera can sent you pictures by email you have to save your email providers data inside the camera. This can be done if you open the web interface of your IP camera and then click on `}<strong parentName="p">{`Alarm`}</strong>{` in order to open the `}<strong parentName="p">{`Email Menu`}</strong>{`.`}</p>
    <p>{`On the right side you now have to fill in the following data:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Sender`}</strong>{`: Please fill in your email address. This can be the same email address as the receiver.`}</li>
      <li parentName="ul"><strong parentName="li">{`Receiver 1`}</strong>{`: Please fill in the email address that should receive the alarm message.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Server`}</strong>{`: Here you have to fill in the SMTP Server from your email provider. Mostly you can find this data on the website of your email provider by searching for SMTP. Otherwise you find a list `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Notification/SMTP_Servers/"
        }}>{`here`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Port`}</strong>{`: Fill in the Port which your email provider is using. Normally this will be either Port 25, 465 or 587. Transport Layer Security Protocol: You only need this if you are using Gmail as your email provider. Please choose either TLS or STARTTLS depending on which one you are using.`}</li>
      <li parentName="ul"><strong parentName="li">{`Need Authentication`}</strong>{`: For almost all email servers this has to be activated so that you can identify yourself with your username and password.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP User`}</strong>{`: Please fill in the username of your email account.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Password`}</strong>{`: Please fill in the password of your email account.`}</li>
      <li parentName="ul"><strong parentName="li">{`Report Internet IP by Mail`}</strong>{`: This function can normally be deactivated. If you activate this service, the camera will send you always the newest internet IP address. Every 24 hours your ISP changes your IP address so the camera will always sent you the newest one. Since the camera has an integrated DDNS address this function is normally not needed.`}</li>
    </ul>
    <p>{`Now please save all settings by clicking `}<strong parentName="p">{`Submit`}</strong>{`. After submitting the data to the camera we recommend you to click `}<strong parentName="p">{`Test`}</strong>{` in order to see if the data you typed in was right.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/29d31/Email_Einstellungen_MJPEG_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHo3nsYw//EABcQAQADAAAAAAAAAAAAAAAAAAEAAiD/2gAIAQEAAQUCqBDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAASAhMf/aAAgBAQAGPwLBW//EABsQAAIDAAMAAAAAAAAAAAAAAAABESFREDFx/9oACAEBAAE/IehRK6IPCFiIWJeC4//aAAwDAQACAAMAAAAQEw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAACAgEFAAAAAAAAAAAAAAAAAREhMUFRgaHh/9oACAEBAAE/ELloxvqZCl8IH4BtQLokLcdjyf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/e4706/Email_Einstellungen_MJPEG_01_EN.avif 230w", "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/d1af7/Email_Einstellungen_MJPEG_01_EN.avif 460w", "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/70e80/Email_Einstellungen_MJPEG_01_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/a0b58/Email_Einstellungen_MJPEG_01_EN.webp 230w", "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/bc10c/Email_Einstellungen_MJPEG_01_EN.webp 460w", "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/426ac/Email_Einstellungen_MJPEG_01_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/e83b4/Email_Einstellungen_MJPEG_01_EN.jpg 230w", "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/e41a8/Email_Einstellungen_MJPEG_01_EN.jpg 460w", "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/29d31/Email_Einstellungen_MJPEG_01_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/41ca17b20bd1c5297f5db4ae033e2ea9/29d31/Email_Einstellungen_MJPEG_01_EN.jpg",
              "alt": "Alarm Notification Setup for the VGA Camera Series",
              "title": "Alarm Notification Setup for the VGA Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`After you have now saved all email settings in your camera you still have to activate the email alarm in order to receive an alarm email when a motion is detected. Therefore please click `}<strong parentName="p">{`Alarm`}</strong>{` and choose `}<strong parentName="p">{`Alarm`}</strong>{` in the sub menu. In the next window please set `}<strong parentName="p">{`Motion Detect Armed`}</strong>{` and activate Send `}<strong parentName="p">{`E-Mail on Alarm`}</strong>{` as show in the screenshot below. After that please choose `}<strong parentName="p">{`Submit`}</strong>{` to save the settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0fc06b9980c03b716832fa6f514365e1/29d31/Email_Einstellungen_MJPEG_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHoXTSrC//EABsQAAIBBQAAAAAAAAAAAAAAAAABEBESISIx/9oACAEBAAEFAlqKOlmaH//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAADEBECD/2gAIAQEABj8CHDNf/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIVEQQcH/2gAIAQEAAT8hILAKziUS6mNQ26iX08P/2gAMAwEAAgADAAAAEEzP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARBh/9oACAEDAQE/EDF//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QJ//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRkTH/2gAIAQEAAT8Qtjg9Y7QeROXkKtfGZG6kreVIns//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fc06b9980c03b716832fa6f514365e1/e4706/Email_Einstellungen_MJPEG_02_EN.avif 230w", "/en/static/0fc06b9980c03b716832fa6f514365e1/d1af7/Email_Einstellungen_MJPEG_02_EN.avif 460w", "/en/static/0fc06b9980c03b716832fa6f514365e1/70e80/Email_Einstellungen_MJPEG_02_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0fc06b9980c03b716832fa6f514365e1/a0b58/Email_Einstellungen_MJPEG_02_EN.webp 230w", "/en/static/0fc06b9980c03b716832fa6f514365e1/bc10c/Email_Einstellungen_MJPEG_02_EN.webp 460w", "/en/static/0fc06b9980c03b716832fa6f514365e1/426ac/Email_Einstellungen_MJPEG_02_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0fc06b9980c03b716832fa6f514365e1/e83b4/Email_Einstellungen_MJPEG_02_EN.jpg 230w", "/en/static/0fc06b9980c03b716832fa6f514365e1/e41a8/Email_Einstellungen_MJPEG_02_EN.jpg 460w", "/en/static/0fc06b9980c03b716832fa6f514365e1/29d31/Email_Einstellungen_MJPEG_02_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0fc06b9980c03b716832fa6f514365e1/29d31/Email_Einstellungen_MJPEG_02_EN.jpg",
              "alt": "Alarm Notification Setup for the VGA Camera Series",
              "title": "Alarm Notification Setup for the VGA Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera detects a motion you will now be informed with an email and 6 pictures in the attachment.`}</p>
    <p><strong parentName="p">{`Notes`}</strong>{`: Please note that the camera cannot detect whether it is a human motion or a motion caused by heavy rain or fast light changes. Depending how many false alarms you will receive you might change the sensibility a little. (1= insensitive and 10= very sensitive).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      